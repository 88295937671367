import { boot } from 'quasar/wrappers';
import axios, { AxiosInstance, isAxiosError } from 'axios';

declare module '@vue/runtime-core' {
  interface ComponentCustomProperties {
    $axios: AxiosInstance;
  }
}
const BASE_URL = process.env.API_URL;

// Be careful when using SSR for cross-request state pollution
// due to creating a Singleton instance here;
// If any client changes this (global) instance, it might be a
// good idea to move this instance creation inside the
// "export default () => {}" function below (which runs individually
// for each client)

const api = axios.create({
  baseURL: BASE_URL,
  withCredentials: true,
  withXSRFToken: true,
});

api.defaults.headers.common['Content-Type'] = 'application/json';

// api.interceptors.response.use(
//   (response) => {
//     return response;
//   },
//   async (error) => {
//     console.log(error);
//     const originalRequest = error.config;
//     const errMessage = error.response.data.message as string;
//     if (errMessage.includes('not logged in') && !originalRequest._retry) {
//       originalRequest._retry = true;
//       await AuthRepository.getToken();
//       return api(originalRequest);
//     }
//     return Promise.reject(error);
//   }
// );

api.interceptors.response.use(
  (response) => {
    return response;
  },
  function (error) {
    if (isAxiosError(error) && error.response) {
      if (error.response.status === 401 || error.response.status === 419) {
        const currentPath = window.location.pathname;
        if (currentPath !== '/user/login') {
          window.location.href = `/user/login?to=${encodeURIComponent(currentPath)}`;
        }
      }
    }
    return Promise.reject(error);
  },
);

export default boot(({ app }) => {
  // for use inside Vue files (Options API) through this.$axios and this.$api

  app.config.globalProperties.$axios = axios;
  // ^ ^ ^ this will allow you to use this.$axios (for Vue Options API form)
  //       so you won't necessarily have to import axios in each vue file

  app.config.globalProperties.$api = api;
  // ^ ^ ^ this will allow you to use this.$api (for Vue Options API form)
  //       so you can easily perform requests against your app's API
});

export { api };
