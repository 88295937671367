<script lang="ts" setup>
import { ref } from 'vue';
import RightDrawer from 'src/components/drawers/RightDrawer.vue';
import LeftDrawer from 'src/components/drawers/LeftDrawer.vue';
import { useMeta } from 'quasar';
import { CookieConsentManager } from '#plugins/CookieConsent/CookieConsent';

CookieConsentManager.run();

const leftDrawerOpen = ref(false);

function toggleLeftDrawer() {
  leftDrawerOpen.value = !leftDrawerOpen.value;
}

const showLeftDrawer = ref(true);

const rightDrawerOpen = ref(false);

function toggleRightDrawer() {
  rightDrawerOpen.value = !rightDrawerOpen.value;
}

function closeRight() {
  rightDrawerOpen.value = false;
}

const metaData = {
  // sets document title
  title: 'Home',
  // optional; sets final title as "Index Page - My Website", useful for multiple level meta
  titleTemplate: (title: string) => `${title} - KIOM Online`,

  // meta tags
  meta: {
    // description: { name: 'description', content: 'Page 1' },
    // keywords: { name: 'keywords', content: 'Quasar website' },
    equiv: {
      'http-equiv': 'Content-Type',
      content: 'text/html; charset=UTF-8',
    },
  },
};
useMeta(metaData);
</script>
<template>
  <q-layout view="lHr LpR lFr">
    <q-header bordered class="bg-primary text-white" height-hint="98">
      <q-toolbar>
        <template v-if="showLeftDrawer">
          <q-btn stretch flat label="Menu" no-caps icon-right="menu" @click="toggleLeftDrawer" />
        </template>
        <q-toolbar-title class="text-center">
          <!--          <span class="text-weight-bolder">KIOM</span>-->
        </q-toolbar-title>
        <q-btn stretch flat icon="account_circle" label="Mijn KIOM" no-caps @click="toggleRightDrawer" />
      </q-toolbar>
    </q-header>
    <template v-if="showLeftDrawer">
      <q-drawer v-model="leftDrawerOpen" show-if-above bordered side="left">
        <LeftDrawer />
      </q-drawer>
    </template>
    <q-drawer v-model="rightDrawerOpen" overlay behavior="mobile" bordered side="right">
      <RightDrawer @close-me="closeRight" />
    </q-drawer>
    <q-page-container>
      <router-view />
    </q-page-container>
    <!--    <q-footer bordered class="bg-grey-6 text-white">-->
    <!--      <q-toolbar>-->
    <!--        <q-space />-->
    <!--        <q-btn stretch flat label="Hulp" />-->
    <!--        <q-btn stretch flat label="Privacy" />-->
    <!--        <q-btn stretch flat label="Profiel" />-->
    <!--      </q-toolbar>-->
    <!--    </q-footer>-->
  </q-layout>
</template>
<style lang="scss">
.body--light {
  color: #303030;
}
</style>
