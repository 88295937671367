<script lang="ts" setup>
import { useMeta } from 'quasar';
import { CookieConsentManager } from '#plugins/CookieConsent';

CookieConsentManager.run();

const metaData = {
  // sets document title
  title: 'Home',
  // optional; sets final title as "Index Page - My Website", useful for multiple level meta
  titleTemplate: (title: string) => `${title} - KIOM Online`,

  // meta tags
  meta: {
    // description: { name: 'description', content: 'Page 1' },
    // keywords: { name: 'keywords', content: 'Quasar website' },
    equiv: {
      'http-equiv': 'Content-Type',
      content: 'text/html; charset=UTF-8',
    },
  },
};
useMeta(metaData);
</script>
<template>
  <q-layout view="hHh lpR fFf">
    <q-page-container>
      <router-view />
    </q-page-container>
  </q-layout>
</template>
