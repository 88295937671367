import { RouteRecordRaw } from 'vue-router';
import MainLayout from 'layouts/main-layout.vue';
import FocusLayout from 'layouts/focus-layout.vue';
import ExamLayout from 'layouts/exam-layout.vue';

const routes: RouteRecordRaw[] = [
  {
    path: '/',
    redirect: '/online-courses',
  },
  {
    path: '/over-ons',
    component: MainLayout,
    children: [
      {
        path: 'privacy',
        name: 'privacy',
        component: () => import('#pages/PrivacyPage.vue'),
        meta: { requiresAuth: false },
      },
      {
        path: 'terms',
        name: 'terms',
        component: () => import('#pages/TermsPage.vue'),
        meta: { requiresAuth: false },
      },
      {
        path: 'help',
        name: 'help',
        component: () => import('#pages/HelpPage.vue'),
        meta: { requiresAuth: false },
      },
    ],
  },
  {
    path: '/bevestigen',
    component: FocusLayout,
    children: [
      {
        path: ':id/:secret',
        name: 'confirmation',
        component: () => import('#pages/confirm-sign-up-page.vue'),
      },
    ],
  },
  {
    path: '/user',
    component: FocusLayout,
    children: [
      {
        path: 'login',
        name: 'auth.login',
        component: () => import('#pages/Auth/LoginPage.vue'),
      },
      {
        path: 'forgot-password',
        name: 'auth.forgot-password',
        component: () => import('#pages/Auth/ForgotPasswordPage.vue'),
      },
      {
        path: 'reset-password',
        name: 'auth.reset-password',
        component: () => import('#pages/Auth/ResetPasswordPage.vue'),
      },
    ],
  },
  {
    path: '/user',
    component: MainLayout,
    children: [
      {
        path: 'profile',
        name: 'auth.profile',
        component: () => import('#pages/Profile/profile-page.vue'),
        meta: { requiresAuth: true },
      },
      {
        path: 'settings/profile',
        name: 'settings.profile',
        component: () => import('#pages/Settings/profile-settings-page.vue'),
        meta: { requiresAuth: true },
      },
      {
        path: 'settings/address',
        name: 'settings.address',
        component: () => import('#pages/Settings/address-settings-page.vue'),
        meta: { requiresAuth: true },
      },
      {
        path: 'settings/security',
        name: 'settings.security',
        component: () => import('#pages/Settings/security-settings-page.vue'),
        meta: { requiresAuth: true },
      },
      {
        path: 'my-applications',
        name: 'auth.my-applications.index',
        component: () => import('#pages/Auth/MyApplications/index-page.vue'),
        meta: { requiresAuth: true },
      },
      {
        path: 'my-applications/:applicationUuid',
        name: 'auth.my-applications.show',
        component: () => import('#pages/Auth/MyApplications/show-page.vue'),
        meta: { requiresAuth: true },
      },
      {
        path: 'my-signups/:signUpUuid',
        name: 'auth.my-sign-ups.show',
        component: () => import('#pages/Auth/MySignUps/show-page.vue'),
        meta: { requiresAuth: true },
      },
    ],
  },
  {
    path: '/opleidingen',
    component: MainLayout,
    children: [
      {
        path: '',
        name: 'education.index',
        component: () => import('#pages/Education/index/education-index-page.vue'),
      },
    ],
  },
  {
    path: '/online-tests',
    component: MainLayout,
    children: [
      {
        path: '',
        name: 'online-tests.index',
        component: () => import('#pages/OnlineCourses/tests/online-tests-index-page.vue'),
      },
      {
        path: '/submission/:testSubmissionUuid',
        name: 'online-tests-submission.show',
        component: () => import('#pages/OnlineCourses/tests/tests-submission-show-page.vue'),
      },
    ],
  },
  {
    path: '/online-tests',
    component: ExamLayout,
    children: [
      {
        path: ':testUuid',
        name: 'online-courses.tests.show',
        component: () => import('#pages/OnlineCourses/tests/tests-show-page.vue'),
        meta: { requiresAuth: true },
      },
    ],
  },
  {
    path: '/online-courses',
    component: MainLayout,
    children: [
      {
        path: '',
        name: 'online-courses.index',
        component: () => import('#pages/OnlineCourses/online-course-index-page.vue'),
      },
      {
        path: ':onlineCourseUuid',
        name: 'online-courses.show',
        component: () => import('#pages/OnlineCourses/online-course-show-page.vue'),
        meta: { requiresAuth: true },
      },
      {
        path: ':onlineCourseUuid/modules/:moduleUuid',
        name: 'online-courses.modules.show',
        component: () => import('#pages/OnlineCourses/modules-show-page.vue'),
        meta: { requiresAuth: true },
      },
      {
        path: ':onlineCourseUuid/modules/:moduleUuid/chapters/:chapterUuid',
        name: 'online-courses.chapters.show',
        component: () => import('#pages/OnlineCourses/chapters-show-page.vue'),
        meta: { requiresAuth: true },
      },
    ],
  },
  {
    path: '/checkout',
    component: FocusLayout,
    children: [
      {
        path: 'online-course/:onlineCourseUuid',
        name: 'online-courses.checkout',
        component: () => import('#pages/Checkout/online-course-checkout-page.vue'),
      },
      {
        path: 'education/:educationUuid',
        name: 'education.register',
        component: () => import('#pages/Education/register/education-register-page.vue'),
      },
    ],
  },
  {
    path: '/order',
    component: FocusLayout,
    children: [
      {
        path: ':orderUuid/success',
        name: 'order.wait-payment',
        component: () => import('#pages/Checkout/order-payment-wait-page.vue'),
      },
    ],
  },

  // Always leave this as last one,
  // but you can also remove it
  {
    path: '/:catchAll(.*)*',
    component: () => import('#pages/ErrorNotFound.vue'),
  },
];

export default routes;
