<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import { RouteLocationRaw } from 'vue-router';

const { t } = useI18n();
const emit = defineEmits(['click']);

interface Props {
  type?: string;
  color?: string;
  labelKey?: string;
  buttonLabelKey?: string | null;
  loading?: boolean;
  disable?: boolean;
  to?: RouteLocationRaw;
}

withDefaults(defineProps<Props>(), {
  type: 'button',
  color: 'primary',
  labelKey: 'buttons.save',
  buttonLabelKey: '',
  loading: false,
  disable: false,
  to: undefined,
});
</script>
<template>
  <q-btn :type="type" :loading="loading" :disable="disable" no-caps :to="to" :color="color" @click="emit('click')">
    <template #default>
      <slot />
      <template v-if="buttonLabelKey">
        {{ t(`buttons.` + buttonLabelKey) ?? '' }}
      </template>
      <template v-else-if="labelKey">
        {{ t(labelKey) ?? '' }}
      </template>
    </template>
    <template #loading>
      <q-spinner-facebook />
    </template>
  </q-btn>
</template>
<i18n>
{
  "en": {
    "buttons": {
      "login": "Login",
      "forgotPassword": "Forgot password?",
      "sendRequest": "Send request"
    }
  },
  "nl": {
    "buttons": {
      "save": "Opslaan",
      "login": "Inloggen",
      "logout": "Uitloggen",
      "forgotPassword": "Wachtwoord vergeten?",
      "register": "Registreren",
      "sendApplication": "Aanmelding verzenden",
      "toMyApplications": "Naar mijn opleidingen",
      "pay": "Betalen",
      "back": "Terug",
      "previous": "Vorige",
      "next": "Volgende",
      "handIn": "Inleveren",
      "open": "Openen",
      "changeProfile": "Wijzig profiel",
      "sendRequest": "Verzoek versturen",
      "backToAddress": "Terug naar Adresgegevens",
      "backToUserSettings": "Terug naar Persoonsgegevens",
      "nextStep": "Door naar volgende stap",
      "downloadCertificate": "Certificaat downloaden",
      "toProfile": "Naar mijn profiel",
      "result": "Resultaat",
      "startTest": "Start toets",
      "showCookiePreferences": "Cookie voorkeuren tonen"
    }
  }
}
</i18n>
