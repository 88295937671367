import 'vanilla-cookieconsent';
import { CookieConsentManagerType, settings as nl } from '#plugins/CookieConsent';
import { logIfDevMode } from 'src/helpers';
import { Pixel } from '#plugins/MetaPixel';
import { bootstrap } from 'vue-gtag';

let initialized = false;
let cookieConsent: CookieConsent | null = null;

function initialize() {
  // Only initialize if this is not a crawler or bot.
  const botRegex = /bot|googlebot|crawler|spider|robot|crawling/i;
  const isBot = navigator.userAgent && botRegex.test(navigator.userAgent);
  if (isBot) {
    logIfDevMode('CC Not initialized because this is a bot.');
    return;
  }

  if (!initialized) {
    cookieConsent = window.initCookieConsent();
  }
  initialized = true;
}

function checkCategories() {
  if (!initialized) return logIfDevMode('CC Not initialized, skip.');
  if (cookieConsent === null) return logIfDevMode('CC Not found.');
  if (cookieConsent.allowedCategory('analytics')) {
    void bootstrap().then(() => {
      logIfDevMode('CC Analytics allowed, bootstrap gtag.');
    });
  }
  if (cookieConsent.allowedCategory('marketing')) {
    logIfDevMode('CC Marketing allowed, bootstrap Pixel.');
    Pixel.initialize({
      id: process.env.PIXEL_ID ?? '',
    });
    const initialized = !!window.fbq;
    if (!initialized) return;
    Pixel.setHasGrantedConsent();
    Pixel.trackPageView();
  } else {
    Pixel.revokeConsent();
  }
}

function run() {
  if (!initialized) return logIfDevMode('CC Not initialized, skip.');
  if (cookieConsent === null) return logIfDevMode('CC Not found.');
  cookieConsent.run({
    revision: 1,
    mode: 'opt-out',
    cookie_expiration: 365,
    force_consent: false,
    current_lang: 'nl',
    onAccept: () => checkCategories(),
    onChange: () => checkCategories(),
    languages: {
      nl: nl,
    },
    gui_options: {
      consent_modal: {
        layout: 'cloud', // box/cloud/bar
        position: 'bottom center', // bottom/middle/top + left/right/center
        transition: 'zoom', // zoom/slide
        swap_buttons: false, // enable to invert buttons
      },
      settings_modal: {
        layout: 'box', // box/bar
        // position: 'left',           // left/right
        transition: 'slide', // zoom/slide
      },
    },
  });
}

export const CookieConsentManager: CookieConsentManagerType = {
  initialize,
  run,
  CookieConsent: () => cookieConsent,
};
