export const settings: LanguageSetting = {
  consent_modal: {
    title: 'KIOM maakt gebruik van cookies',
    description:
      'Wij, en derde partijen, maken op onze website gebruik van cookies. Wij gebruiken cookies voor het bijhouden van statistieken, om voorkeuren op te slaan, maar ook voor marketingdoeleinden. Door op "Accepteren" te klikken, ga je akkoord met het gebruik van alle cookies zoals omschreven in onze <a href="/over-ons/privacy">privacyverklaring</a>.',
    primary_btn: {
      text: 'Accepteren',
      role: 'accept_all', // 'accept_selected' or 'accept_all'
    },
    secondary_btn: {
      text: 'Voorkeuren aanpassen',
      role: 'settings', // 'settings' or 'accept_necessary'
    },
  },
  settings_modal: {
    title: 'Cookie voorkeuren ...',
    save_settings_btn: 'Opslaan',
    accept_all_btn: 'Accepteren',
    blocks: [
      {
        title: 'KIOM maakt gebruik van cookies',
        description:
          'Wij, en derde partijen, maken op onze website gebruik van cookies. Wij gebruiken cookies voor het bijhouden van statistieken, om voorkeuren op te slaan, maar ook voor marketingdoeleinden. Door op "Accepteren" te klikken, ga je akkoord met het gebruik van alle cookies zoals omschreven in onze <a href="/over-ons/privacy">privacyverklaring</a>.',
      },
      {
        title: 'Functioneel en analytisch',
        description:
          'Deze cookies zijn noodzakelijk voor het functioneren van de website en het verbeteren van de website-ervaring.',
        toggle: {
          value: 'analytics',
          enabled: true,
          readonly: true,
        },
      },
      {
        title: 'Marketing',
        description:
          'We gebruiken deze cookies om je surfgedrag op onze website en je profiel in te zetten voor onze eigen marketingdoeleinden. Zo kun je dankzij deze cookies advertenties zien voor onze producten en diensten via onze website en op websites van derden.',
        toggle: {
          value: 'marketing',
          enabled: true,
          readonly: false,
        },
      },
    ],
  },
};
