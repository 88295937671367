<script setup lang="ts">
import KiomButton from '#components/stepper/kiom-button.vue';
import { useUserStore } from '#stores/user.store';
import { storeToRefs } from 'pinia';
import { useRouter } from 'vue-router';
import { useQueryClient } from '@tanstack/vue-query';

const queryClient = useQueryClient();
const userStore = useUserStore();
const { user } = storeToRefs(userStore);
const router = useRouter();

const emits = defineEmits(['logout-success']);

async function handleLogout() {
  await userStore.logout();
  emits('logout-success');
  await queryClient.invalidateQueries();
  await router.push({ path: '/' });
}

const menuList = [
  { label: 'Profiel', icon: 'account_circle', to: { name: 'auth.profile' } },
  // { label: 'Mijn Online cursussen', to: { name: 'online-courses.index' } },
  {
    label: 'Mijn Opleiding(en)',
    icon: 'school',
    to: { name: 'auth.my-applications.index' },
  },
  // { label: 'Mijn Agenda', icon: 'calendar_month', to: { name: 'orders' } },
  {
    label: 'Instellingen',
    icon: 'settings',
    to: { name: 'settings.profile' },
  },
];
</script>
<template>
  <template v-if="user">
    <div class="text-h6 q-py-md text-weight-bold q-px-md text-center bg-grey-3">
      {{ user.name }}
    </div>
    <q-scroll-area style="height: 334px">
      <q-list>
        <q-separator />
        <template v-for="(menuItem, index) in menuList" :key="index">
          <q-item v-ripple :to="menuItem.to" exact class="q-px-md q-py-md text-weight">
            <q-item-section side>
              <q-icon name="chevron_right" size="md" />
            </q-item-section>
            <q-item-section>
              {{ menuItem.label }}
            </q-item-section>
          </q-item>
          <q-separator />
        </template>
      </q-list>
    </q-scroll-area>
    <div class="text-center q-mt-lg">
      <kiom-button button-label-key="logout" @click="handleLogout" />
    </div>
  </template>
  <template v-else>
    <div class="q-py-lg text-center bg-grey-3">
      <kiom-button button-label-key="login" :to="{ name: 'auth.login' }" />
    </div>
  </template>
</template>
