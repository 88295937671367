import { defineStore } from 'pinia';
import { computed, Ref, ref } from 'vue';
import { UserResponse } from '#api/types';
import AuthRepository from '#api/Repositories/AuthRepository';

export const useUserStore = defineStore('user', () => {
  const user: Ref<UserResponse | null> = ref(null);

  const isAuth = computed(() => !!user.value);

  const address = computed(() => user.value?.address);
  const isAddressFilled = computed(() => {
    const address = user.value?.address;
    if (!address) return false;

    return Boolean(address.postalCode && address.houseNumber && address.street && address.city && address.country);
  });

  const isProfileFilled = computed(() => {
    const userFn = user.value;
    if (!userFn) return false;
    return Boolean(
      userFn.firstName && userFn.lastName && userFn.birthDate && userFn.birthDate.formatted !== '' && userFn.phone,
    );
  });

  function setUser(setUser: UserResponse) {
    user.value = setUser;
  }

  async function tryFetchUser() {
    const userResponse = await AuthRepository.currentUser();
    if (userResponse.user) {
      setUser(userResponse.user);
      return true;
    }
    return false;
  }

  async function tryFetchAddress() {
    if (user.value) {
      const addressResponse = await AuthRepository.address();
      if (addressResponse) {
        user.value.address = addressResponse;
        return true;
      }
    }
    return false;
  }

  async function logout() {
    await AuthRepository.logout();
    user.value = null;
  }

  return { user, isAuth, address, isAddressFilled, isProfileFilled, tryFetchUser, tryFetchAddress, logout };
});
