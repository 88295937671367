import { doInitializeMetaPixel } from '#plugins/MetaPixel/initializer';
import { MetaPixel, MetaPixelConfig, TrackParameters } from '#plugins/MetaPixel/types';

let isConsentGranted = false;
let initialized = false;

function logIfDevMode(message: string): void {
  if (process.env.NODE_ENV === 'development') console.log(message);
}

/** To revoke consent to track data using Pixel. Read more at https://developers.facebook.com/docs/meta-pixel/implementation/gdpr */
const revokeConsent = (): void => {
  if (isConsentGranted) {
    isConsentGranted = false;
    fbq('consent', 'revoke');
    logIfDevMode('Track fbq("consent", "revoke")');
  }
};

/** To save granted consent to track data using Pixel. Read more at https://developers.facebook.com/docs/meta-pixel/implementation/gdpr */
const setHasGrantedConsent = (): void => {
  if (isConsentGranted) return;
  isConsentGranted = true;
  fbq('consent', 'grant');
  logIfDevMode('Track fbq("consent", "grant")');
};

/** Read more about initializing at https://developers.facebook.com/docs/meta-pixel/get-started */
const initialize = ({ id: pixelId }: MetaPixelConfig): void => {
  if (!(typeof window !== 'undefined' && !!window))
    return console.error('Window not available, cannot initialize Meta Pixel');

  const alreadyInitialized = !!window.fbq;
  if (alreadyInitialized) return;

  if (!pixelId) {
    return console.log(
      'Please provide your pixelId to initialize an Meta Pixel instance',
    );
  }
  revokeConsent();
  doInitializeMetaPixel();

  fbq('init', pixelId);
  logIfDevMode(`Init, fbq("init", ${pixelId})`);
  initialized = true;
};

const track = (eventName: string, parameters?: TrackParameters): void => {
  if (!initialized) return console.log('Pixel not initialized.');
  if (parameters === undefined) {
    fbq('track', eventName);
    logIfDevMode(`Track fbq("track", ${eventName})`);
  } else {
    fbq('track', eventName, parameters);
    logIfDevMode(
      `Track fbq("track", ${eventName}, ${JSON.stringify(parameters)})`,
    );
  }
};

const trackPageView = (): void => {
  if (!initialized) return logIfDevMode('Pixel not initialized.');
  track('PageView');
};

const trackSubmitApplication = (): void => {
  track('SubmitApplication');
};

export const Pixel: MetaPixel = {
  setHasGrantedConsent,
  revokeConsent,
  initialize,
  trackPageView,
  trackSubmitApplication,
};
