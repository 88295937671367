import confetti from 'canvas-confetti';

export function logIfDevMode(...args: unknown[] | string[]): void {
  if (process.env.NODE_ENV === 'development') console.log(...args);
}

export function confettiRun() {
  // do this for 1.5 seconds.
  const end = Date.now() + 1500;

  (function frame() {
    function randomInRange(min: number, max: number) {
      return Math.random() * (max - min) + min;
    }

    const defaults = { startVelocity: 11, spread: 360, ticks: 60, zIndex: 0 };
    void confetti(
      Object.assign({}, defaults, {
        particleCount: 30,
        origin: { x: randomInRange(0.05, 0.95), y: Math.random() },
      }),
    );

    // keep going until we are out of time
    if (Date.now() < end) {
      requestAnimationFrame(frame);
    }
  })();
}
