<script setup lang="ts">
import UserMenu from '#components/drawers/components/UserMenu.vue';

const emits = defineEmits(['close-me']);

function closeMe() {
  emits('close-me');
}
</script>
<template>
  <div class="column items-center q-pt-md bg-grey-3">
    <q-avatar size="155px" square>
      <q-img fit="contain" height="155px" loading="lazy" spinner-color="white" src="~assets/bg.webp" />
    </q-avatar>
  </div>
  <UserMenu @logout-success="closeMe" />
</template>
