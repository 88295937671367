import {
  AddressRequest,
  AddressResponse,
  AddressSuggestRequest,
  ForgotPasswordRequest,
  ForgotPasswordResponse,
  LoginRequest,
  LoginResponse,
  ProfileRequest,
  RegisterRequest,
  ResetPasswordRequest,
  ResetPasswordResponse,
  SecurityRequest,
  SuccessResponse,
  WrappedUserResponse
} from '#api/types';
import { api } from '#boot/axios';
import { AxiosResponse } from 'axios';

export default class AuthRepository {
  public static async csrf() {
    // Sets a cookie in the browser, does not need to be emitted.
    await api.get('auth/csrf-cookie');
  }

  public static async forgotPassword(data: ForgotPasswordRequest): Promise<ForgotPasswordResponse> {
    await this.csrf();
    const response: AxiosResponse<ForgotPasswordResponse> = await api.post('auth/forgot-password', data);
    return response.data;
  }

  public static async resetPassword(data: ResetPasswordRequest): Promise<ResetPasswordResponse> {
    await this.csrf();
    const response: AxiosResponse<ResetPasswordResponse> = await api.post('auth/reset-password', data);
    return response.data;
  }

  public static async login(credentials: LoginRequest): Promise<LoginResponse | null> {
    await this.csrf();
    const response: AxiosResponse<LoginResponse> = await api.post('auth/login', credentials);
    return response?.data ?? null;
  }

  public static async register(credentials: RegisterRequest): Promise<SuccessResponse> {
    await this.csrf();
    const response: AxiosResponse<SuccessResponse> = await api.post('auth/register', credentials);
    return response.data;
  }

  public static async updateProfile(data: ProfileRequest): Promise<SuccessResponse> {
    await this.csrf();
    const response: AxiosResponse<SuccessResponse> = await api.post('auth/update-profile', data);
    return response.data;
  }

  public static async updateSecurity(data: SecurityRequest): Promise<SuccessResponse> {
    await this.csrf();
    const response: AxiosResponse<SuccessResponse> = await api.post('auth/update-security', data);
    return response.data;
  }

  public static async updateAddress(data: AddressRequest): Promise<SuccessResponse> {
    await this.csrf();
    const response: AxiosResponse<SuccessResponse> = await api.post('auth/update-address', data);
    return response.data;
  }

  public static async suggestAddress(data: AddressSuggestRequest): Promise<AddressResponse> {
    await this.csrf();
    const response: AxiosResponse<AddressResponse> = await api.post('auth/suggest-address', data);
    return response.data;
  }

  public static async logout(): Promise<SuccessResponse> {
    await this.csrf();
    const response: AxiosResponse<SuccessResponse> = await api.post('auth/logout');
    return response.data;
  }

  public static async currentUser(): Promise<WrappedUserResponse> {
    const response: AxiosResponse<WrappedUserResponse> = await api.get('auth/current-user');
    return response.data;
  }

  public static async address(): Promise<AddressResponse> {
    const response: AxiosResponse<AddressResponse> = await api.get('auth/address');
    return response.data;
  }
}
