/* eslint-disable @typescript-eslint/no-explicit-any */
export const doInitializeMetaPixel = (): void => {
  !(function (
    f: any,
    b: any,
    e: any,
    v: any,
    n?: any,
    t?: any,
    s?: any,
  ): boolean | undefined {
    if (f.fbq) return;
    n = f.fbq = function (...args: never[]) {
      n.callMethod ? n.callMethod(...args) : n.queue.push(args);
    };
    if (!f._fbq) f._fbq = n;
    n.push = n;
    n.loaded = !0;
    n.version = '2.0';
    n.queue = [];
    t = b.createElement(e);
    t.async = !0;
    t.src = v;
    s = b.getElementsByTagName(e)[0];
    s.parentNode.insertBefore(t, s);
  })(
    window,
    document,
    'script',
    'https://connect.facebook.net/en_US/fbevents.js',
  );
};
