<script setup lang="ts">
const links = [
  {
    text: 'Online cursussen',
    to: { name: 'online-courses.index' },
  },
  {
    text: 'Online toetsen',
    to: { name: 'online-tests.index' },
  },
];
const links2 = [
  { icon: 'help', text: 'Hulp', to: { name: 'help' }, separator: true },
  { icon: 'policy', text: 'Privacyverklaring', to: { name: 'privacy' } },
  { icon: 'gavel', text: 'Algemene voorwaarden', to: { name: 'terms' } },
];
</script>
<template>
  <q-scroll-area
    class="fit items-stretch"
    :content-style="{
      display: 'flex',
      'flex-direction': 'column',
    }"
  >
    <div class="column items-center q-my-lg">
      <q-img fit="contain" height="155px" loading="lazy" spinner-color="white" src="~assets/logo230.webp" />
    </div>
    <q-list>
      <q-separator />
      <template v-for="(link, index) in links" :key="'links-' + index">
        <q-item v-ripple :to="link.to" exact class="q-px-md q-py-md text-weight">
          <q-item-section class="text-center text-weight-regular">
            {{ link.text }}
          </q-item-section>
        </q-item>
        <q-separator />
      </template>
    </q-list>
    <q-space />
    <q-list>
      <q-separator />
      <template v-for="(link, index) in links2" :key="'links2-' + index">
        <q-item v-ripple :to="link.to" clickable class="">
          <q-item-section avatar>
            <q-icon color="grey" :name="link.icon" />
          </q-item-section>
          <q-item-section>
            <q-item-label>{{ link.text }}</q-item-label>
          </q-item-section>
        </q-item>
        <q-separator />
      </template>
    </q-list>
  </q-scroll-area>
</template>
